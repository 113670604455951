<template>
  <FormGroupElement :title="$helpers.string.capitalizeI18N('address')" :expanded="showAddress" v-bind="$attrs">
    <AddressEdition v-model="$attrs['value']" />
  </FormGroupElement>
</template>

<script>
import AddressEdition from "./AddressEdition";
import FormGroupElement from "@/components/Admin/FormGroupElement";

export default {
  name: "FormGroupAddressEdition",
  components: {
    AddressEdition,
    FormGroupElement,
  },
  computed: {
    showAddress() {
      const address = this.$attrs["value"];

      return (
        address != null &&
        (address.addressLine1 != null ||
          address.addressLine2 != null ||
          address.zipCode != null ||
          address.city != null ||
          address.countryId != null)
      );
    },
  },
};
</script>
<template>
  <v-container>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :label="$helpers.string.capitalizeI18N('address-line-1')"
          v-model="address.addressLine1"
          prepend-icon="mdi-email"
          type="text"
        />
      </v-col>
    </v-row>
    <v-row no-gutters>
      <v-col>
        <v-text-field
          :label="$helpers.string.capitalizeI18N('address-line-2')"
          v-model="address.addressLine2"
          prepend-icon="mdi-email"
          type="text"
        />
      </v-col> </v-row
    ><v-row no-gutters>
      <v-col>
        <v-text-field
          :label="$helpers.string.capitalizeI18N('zip-code')"
          v-model="address.zipCode"
          prepend-icon="mdi-numeric"
          type="text"
        /> </v-col
      ><v-col>
        <v-text-field
          :label="$helpers.string.capitalizeI18N('city')"
          v-model="address.city"
          prepend-icon="mdi-city"
          type="text"
        />
      </v-col> </v-row
    ><v-row no-gutters>
      <v-col> <CountrySelector v-model="address.countryId" /></v-col>
    </v-row>
  </v-container>
</template>

<script>
import Address from "@/models/address";
import CountrySelector from "@/components/Admin/CountrySelector";

export default {
  name: "Address",
  components: {
    CountrySelector,
  },
  watch: {},
  computed: {
    address() {
      return this.$attrs.value ?? new Address();
    },
  },
  data() {
    return {
      showMore: false,
    };
  },
  methods: {},
};
</script>
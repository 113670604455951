<template>
  <v-autocomplete
    :label="$helpers.string.capitalizeI18N('country')"
    prepend-icon="mdi-flag"
    :items="countries"
    v-bind="$attrs"
    v-on="$listeners"
    item-value="id"
    :item-text="getName"
    clearable
    @click:clear="clearClicked()"
    :autocomplete="new Date().getTime().toString()"
    auto-select-first
    
  >
  <!-- <template v-slot:item="data">
    <v-avatar><v-img :src="`https://restcountries.eu/data/${data.item.isoCode3 || 'bel'}.svg`" /></v-avatar> {{ getName(data.item) }}
  </template> -->
  </v-autocomplete>
</template>

<script>
export default {
  name: "CountrySelector",
  data() {
    return {
      countries: [],
    };
  },
  mounted() {
    this.retrieveCountries();
  },
  methods: {
    getName(country) {
      return country.nameFr;
    },
    clearClicked() {
      this.$nextTick(() => {
        this.$attrs["value"] = null;
        this.$emit("input", this.$attrs["value"]);
      });
    },
    retrieveCountries() {
      return this.$services.country
        .getAll()
        .then((response) => {
          this.countries = response.data;
        })
        .catch(() => {
          this.$helpers.snackbar.showErrorI18N("countries.error.retrieve");
        });
    },
  },
};
</script>
<template>
  <v-container>
    <v-row>
      <v-col>
        <SearchBox v-model="search" @plusClicked="newSite" />
      </v-col>
    </v-row>
    <v-row>
      <v-col>

        <v-data-table :headers="[

          { text: 'Nom', value: 'name' },
          { text: 'Actions', value: 'actions', sortable: false, },
        ]" :items="sites" :search="search" :loading="loading" :hide-default-footer="true" disable-pagination
          :sort-by="sortBy" :sort-desc="sortDesc">

          <template v-slot:item.name="{ item }">
            {{ item.name }}

            <v-chip v-if="item.active == 0" color="red" small text-color="white" class="ml-2">{{
              $t("inactive")
            }}</v-chip>
          </template>

          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn @click="editSite(item)" v-bind="attrs" v-on="on" icon>
                  <v-icon>
                    mdi-pencil-box
                  </v-icon>
                </v-btn>
              </template>
              <span>Modifier le site</span>
            </v-tooltip>

          </template>
          <template v-slot:no-data>
            <NoTableData @click="newSite" />
          </template>
          <template v-slot:loading>
            <Loader />
          </template>
        </v-data-table>

        <!-- <v-data-iterator :items="sites" :search="search" :loading="loading">
          <template v-slot:loading>
            <Loader />
          </template>
          <template v-slot:no-data>
            <NoTableData @click="newSite" />
          </template>
          <template v-slot:default="props">
            <v-row>
              <v-col v-for="item in props.items" :key="item.id" cols="12" sm="6" md="4" lg="3">
                <SiteCard v-bind:site="item" @onEditClicked="editSite" />
              </v-col>
            </v-row>
          </template>
        </v-data-iterator> -->
      </v-col>
    </v-row>
    <SiteEditionPopup ref="siteEdition" @onSaveClicked="saveClicked" @onCloseClicked="retrieveAll" />
  </v-container>
</template>

<script>
// import SiteCard from "@/components/Admin/Site/SiteCard";
import SiteEditionPopup from "@/components/Admin/Site/SiteEditionPopup";
import SearchBox from "@/components/Admin/SearchBox";
import Loader from "@/components/Admin/Loader";
import NoTableData from "@/components/Admin/NoTableData.vue";

export default {
  components: {
    // SiteCard,
    SiteEditionPopup,
    SearchBox,
    Loader,
    NoTableData,
  },
  data() {
    return {
      loading: false,
      search: "",
      filter: {},
      sites: [],
      sortBy: "name",
      sortDesc: false,
    };
  },
  computed: {
    numberOfPages() {
      return Math.ceil(this.sites.length / this.itemsPerPage);
    },
    filteredKeys() {
      return this.keys.filter((key) => key !== `Name`);
    },
  },
  methods: {
    newSite() {
      this.$refs.siteEdition.open({ active: true });
    },
    editSite(site) {
      var value = JSON.parse(JSON.stringify(site));
      this.$refs.siteEdition.open(value);
    },
    nextPage() {
      if (this.page + 1 <= this.numberOfPages) this.page += 1;
    },
    formerPage() {
      if (this.page - 1 >= 1) this.page -= 1;
    },
    updateItemsPerPage(number) {
      this.itemsPerPage = number;
    },
    saveClicked() {
      this.retrieveAll();
    },
    retrieveSites() {
      this.loading = true;
      this.$services.site
        .getAll()
        .then((response) => {
          this.sites = response.data;
          this.loading = false;
        })
        .catch((error) => {
          this.$helpers.snackbar.handleError(error);
        });
    },
    retrieveAll() {
      this.retrieveSites();
    },
  },
  mounted() {
    this.retrieveAll();
  },
};
</script>
<template>
  <v-row justify="center">
    <v-dialog
      ref="dialog"
      v-model="dialog"
      :return-value.sync="site"
      persistent
      max-width="800px"
      @keydown.esc="close()"
      :fullscreen="$helpers.display.mobileDialog"
      :hide-overlay="$helpers.display.mobileDialog"
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-card-title>
          <span class="headline">{{
            site.id == null
              ? $helpers.string.capitalizeI18N("site.creation")
              : site.name
          }}</span
          ><v-switch
            class="ml-4"
            v-model="site.active"
            v-show="site.id"
            :label="$helpers.string.capitalizeI18N('active')"
          ></v-switch>
          <v-spacer />
          <PopupCloseButton @closeClick="close" />
        </v-card-title>
        <v-card-text>
          <v-form ref="form">
            <FormGroupElement :showMoreButton="false" :expanded="true">
              <v-container>
                <v-row>
                  <v-col>
                    <v-text-field
                      :label="
                        $helpers.string.capitalizeI18N('name', {
                          required: true,
                        })
                      "
                      v-model="site.name"
                      required
                      autofocus
                      :rules="$helpers.rule.notEmpty()"
                    ></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
            </FormGroupElement>
            <FormGroupAddressEdition v-model="site.address" />
          </v-form>
          <small>* {{ $t("required-fields") }}</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <CardActionButton
            @click="saveAndClose"
            type="save"
            ref="saveButton"
          />
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>


<script>
import CardActionButton from "@/components/Admin/Card/CardActionButton";
import FormGroupElement from "@/components/Admin/FormGroupElement";
import FormGroupAddressEdition from "@/components/Admin/Address/FormGroupAddressEdition";
import PopupCloseButton from "@/components/Admin/PopupCloseButton";

import Address from "@/models/address";

export default {
  name: "SiteEditionPopup",
  data() {
    return {
      dialog: false,
      site: {},
      menu: false,
    };
  },
  components: {
    CardActionButton,
    FormGroupElement,
    FormGroupAddressEdition,
    PopupCloseButton,
  },
  methods: {
    async saveSite() {
      try {
        const response = await this.$services.site.save(this.site);

        this.site = response.data;

        return Promise.resolve();
      } catch (error) {
        return Promise.reject(error);
      }
    },
    loadSite() {
      if (!this.site.address) this.site.address = new Address();
    },
    async saveAndClose() {
      try {
        this.$refs.saveButton.loading = true;

        if (!this.$refs.form.validate()) return;

        await this.saveSite();

        this.$helpers.snackbar.showSuccessI18N("site.save.success");

        this.$emit("onSaveClicked", this.site);
        this.dialog = false;
      } catch (error) {
        this.$helpers.snackbar.handleError(error);
      } finally {
        this.$refs.saveButton.loading = false;
      }
    },
    saveAndNew() {
      this.saveSite();
      this.$emit("onSaveClicked", this.site);
      this.site = {};
      this.loadSite();
      this.$refs.name.focus();
    },
    close() {
      this.dialog = false;
      this.$emit("onCloseClicked");
    },
    open(site) {
      this.site = site;
      this.loadSite();
      this.dialog = true;
    },
  },
};
</script>
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VRow,{attrs:{"justify":"center"}},[_c(VDialog,{ref:"dialog",attrs:{"return-value":_vm.site,"persistent":"","max-width":"800px","fullscreen":_vm.$helpers.display.mobileDialog,"hide-overlay":_vm.$helpers.display.mobileDialog,"transition":"dialog-bottom-transition"},on:{"update:returnValue":function($event){_vm.site=$event},"update:return-value":function($event){_vm.site=$event},"keydown":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"esc",27,$event.key,["Esc","Escape"])){ return null; }return _vm.close()}},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.site.id == null ? _vm.$helpers.string.capitalizeI18N("site.creation") : _vm.site.name))]),_c(VSwitch,{directives:[{name:"show",rawName:"v-show",value:(_vm.site.id),expression:"site.id"}],staticClass:"ml-4",attrs:{"label":_vm.$helpers.string.capitalizeI18N('active')},model:{value:(_vm.site.active),callback:function ($$v) {_vm.$set(_vm.site, "active", $$v)},expression:"site.active"}}),_c(VSpacer),_c('PopupCloseButton',{on:{"closeClick":_vm.close}})],1),_c(VCardText,[_c(VForm,{ref:"form"},[_c('FormGroupElement',{attrs:{"showMoreButton":false,"expanded":true}},[_c(VContainer,[_c(VRow,[_c(VCol,[_c(VTextField,{attrs:{"label":_vm.$helpers.string.capitalizeI18N('name', {
                        required: true,
                      }),"required":"","autofocus":"","rules":_vm.$helpers.rule.notEmpty()},model:{value:(_vm.site.name),callback:function ($$v) {_vm.$set(_vm.site, "name", $$v)},expression:"site.name"}})],1)],1)],1)],1),_c('FormGroupAddressEdition',{model:{value:(_vm.site.address),callback:function ($$v) {_vm.$set(_vm.site, "address", $$v)},expression:"site.address"}})],1),_c('small',[_vm._v("* "+_vm._s(_vm.$t("required-fields")))])],1),_c(VCardActions,[_c(VSpacer),_c('CardActionButton',{ref:"saveButton",attrs:{"type":"save"},on:{"click":_vm.saveAndClose}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }